import React from 'react';

const sizes = {
    textlg: 'text-lg font-normal not-italic lg:text-[15px]',
    text2xl: 'text-[21px] font-normal not-italic lg:text-[17px]',
    text3xl:
        'text-[28px] font-normal not-italic lg:text-[23px] md:text-[26px] sm:text-2xl',
    text4xl:
        'text-4xl font-normal not-italic lg:text-3xl md:text-[34px] sm:text-[32px]',
    text5xl:
        'text-[46px] font-normal not-italic lg:text-[39px] md:text-[42px] sm:text-4xl',
};

export type TextProps = Partial<{
    className: string;
    as: any;
    size: keyof typeof sizes;
}> &
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLSpanElement>,
        HTMLSpanElement
    >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
    children,
    className = '',
    as,
    size = 'text3xl',
    ...restProps
}) => {
    const Component = as || 'p';

    return (
        <Component
            className={`font-dmsans text-transparent ${className} ${sizes[size]}`}
            {...restProps}
        >
            {children}
        </Component>
    );
};

export { Text };
